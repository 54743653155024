/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react';
import Link from 'next/link';

import Container from '@/components/Container/Container';

import SearchInput from '../SearchInput/SearchInput';
import styles from './Header.module.sass';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const headerClass = `${styles.header} ${isMenuOpen ? styles.headerOpen : ''}`;
  const menuClass = `${styles.menu} ${isMenuOpen ? styles.menuOpen : ''}`;
  const burgerClass = `${styles.burgerIcon} ${isMenuOpen ? styles.open : ''}`;

  const closeMenu = () => setIsMenuOpen(false);

  const toggleMenu = (e) => {
    e.preventDefault();
    setIsMenuOpen((isOpen) => !isOpen);
  };

  const menuItems = [
    {
      href: '/diary',
      title: 'Cake Diary',
    },
    {
      href: '/issues',
      title: 'Issues',
    },
    {
      href: 'https://vimeo.com/user10253399',
      isExternal: true,
      title: 'Cake TV',
    },
    {
      href: '/buy',
      isExternal: true,
      title: 'Buy Print + Digital!',
    },
    {
      href: '/merch',
      isExternal: true,
      title: 'Merch',
    },
  ];

  return (
    <header className={headerClass}>
      <Container className={styles.container}>
        <Link className={styles.logo} href="/">
          <a onClick={closeMenu}>
            <h1>Cake Magazine</h1>
          </a>
        </Link>
        <nav className={styles.menuBar}>
          <div className={burgerClass} onClick={toggleMenu}>
            Menu
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div className={menuClass}>
            <ul>
              {menuItems.map(({ href, isExternal, title }) => (
                <li key={href}>
                  {isExternal ? (
                    <a
                      href={href}
                      onClick={closeMenu}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {title}
                    </a>
                  ) : (
                    <Link href={href}>
                      <a onClick={closeMenu}>{title}</a>
                    </Link>
                  )}
                </li>
              ))}
              <li className={styles.institutionalLinks}>
                <Link href="/about">About us</Link>
                <Link href="/contact">Contact</Link>
                <Link href="/cookie-policy">Cookie Policy</Link>
              </li>
              <li className={styles.search}>
                <SearchInput />
              </li>
              <li className={styles.socialIcons}>
                <a
                  aria-label="Follow Cake on Instagram"
                  href="https://www.instagram.com/cakemagazine"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Follow @cakemagazine on Instagram"
                    height={20}
                    width={20}
                    src="/icons/instagram.svg"
                  />
                </a>
                <a
                  aria-label="Follow Cake on Facebook"
                  href="https://www.fb.com/cakemag"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Follow Cake on Facebook"
                    height={20}
                    width={20}
                    src="/icons/facebook.svg"
                  />
                </a>
                <a
                  aria-label="Follow Cake on Vimeo"
                  href="https://vimeo.com/user10253399"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Follow Cake on Vimeo"
                    height={20}
                    width={20}
                    src="/icons/vimeo.svg"
                  />
                </a>
                <a
                  aria-label="Follow Cake on Tumblr"
                  href="https://cake-mag.tumblr.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Follow Cake on Tumblr"
                    height={20}
                    width={20}
                    src="/icons/tumblr.svg"
                  />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </Container>
    </header>
  );
};

export default Header;
