import React from 'react';
import PropTypes from 'prop-types';

import styles from './Button.module.sass';

const Button = ({ children, onClick, type }) => (
  <button className={styles.button} onClick={onClick} type={type}>
    {children}
  </button>
);

export default Button;

Button.defaultProps = {
  onClick: () => {},
};

Button.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  type: PropTypes.string,
};
