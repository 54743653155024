import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';

import styles from './PostItem.module.sass';

const PostItem = ({ featuredImage, index, slug, title }) => (
  <Link href={`/${slug}`} prefetch={false}>
    <a>
      <div className={styles.postItem}>
        <div className={styles.overlay}>
          <span>{title}</span>
        </div>
        <div className={styles.image}>
          {featuredImage?.node?.sourceUrl ? (
            <Image
              alt={title}
              height={500}
              layout="responsive"
              objectFit="contain"
              priority={index < 3}
              src={featuredImage?.node?.sourceUrl}
              unoptimized
              width={500}
            />
          ) : (
            <div className={styles.fallbackImage}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img alt="fallback image" width="200" src="/cake-logo.svg" />
            </div>
          )}
        </div>
      </div>
    </a>
  </Link>
);

export default PostItem;

PostItem.propTypes = {
  featuredImage: PropTypes.object,
  index: PropTypes.number,
  slug: PropTypes.string,
  title: PropTypes.string,
};
