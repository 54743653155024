import React from 'react';
import PropTypes from 'prop-types';

import styles from './SearchInput.module.sass';

const SearchInput = ({ defaultValue }) => (
  <div className={styles.searchContainer}>
    <form action="/search">
      <input
        autoCapitalize="off"
        autoComplete="off"
        defaultValue={defaultValue}
        name="q"
        placeholder="Search"
        required
        type="search"
      />
      <button type="submit">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img alt="Search" height={20} width={20} src="/icons/search.svg" />
      </button>
    </form>
  </div>
);

export default SearchInput;

SearchInput.propTypes = {
  defaultValue: PropTypes.string,
};
