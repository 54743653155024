import { gql } from '@apollo/client';

export const getIssues = () => ({
  query: gql`
    fragment CoverImage on MediaItem {
      mediaDetails {
        width
        height
      }
      altText
      sourceUrl(size: MEDIUM)
      title
    }

    query Issues {
      categories(
        where: { orderby: SLUG, nameLike: "issue", order: DESC }
        first: 100
      ) {
        nodes {
          name
          slug
          categoriesExtra {
            buylink
            buylinkalternative
            featureimage {
              ...CoverImage
            }
          }
          count
          posts(first: 1) {
            nodes {
              slug
            }
          }
        }
      }
    }
  `,
});

export const getIssueQuery = ({ count = 48, slug }) => ({
  query: gql`
    query IssuePosts($count: Int, $slug: String!) {
      categories(first: 1, where: { slug: [$slug] }) {
        nodes {
          categoriesExtra {
            buylink
            buylinkalternative
            featureimage {
              sourceUrl
            }
            featureimagealternative {
              sourceUrl
            }
          }
          name
        }
      }
      posts(where: { categoryName: $slug }, first: $count) {
        nodes {
          featuredImage {
            node {
              sourceUrl(size: _2048X2048)
            }
          }
          id
          slug
          title(format: RENDERED)
          uri
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `,
  variables: { count, slug },
});

export const getPostsQuery = ({ count = 1 } = {}) => ({
  query: gql`
    query HomePosts($after: String, $count: Int) {
      posts(
        after: $after
        first: $count
        where: { orderby: { field: DATE, order: DESC } }
      ) {
        nodes {
          id
          slug
          title
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
      }
      categories(
        where: { order: DESC, orderby: SLUG, nameLike: "issue" }
        first: 1
      ) {
        nodes {
          categoriesExtra {
            buylink
            buylinkalternative
            featureimage {
              sourceUrl(size: _2048X2048)
            }
          }
          name
          posts {
            nodes {
              slug
              title
            }
          }
        }
      }
    }
  `,
  variables: { count },
});

export const getPostQuery = ({ slug }) => ({
  query: gql`
    query PostDetail($slug: String!) {
      postBy(slug: $slug) {
        categories {
          nodes {
            name
            uri
          }
        }
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            sourceUrl(size: LARGE)
          }
        }
        id
        title
      }
    }
  `,
  variables: {
    slug,
  },
});

export const getSearchQuery = ({ after = '', count = 48, term }) => ({
  query: gql`
    query SearchResults($after: String, $count: Int, $term: String!) {
      posts(
        after: $after
        first: $count
        where: { orderby: { field: DATE, order: DESC }, search: $term }
      ) {
        edges {
          node {
            excerpt
            featuredImage {
              node {
                sourceUrl
              }
            }
            id
            slug
            title
            uri
          }
        }
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
      }
    }
  `,
  variables: {
    after,
    count,
    term,
  },
});

export const getContactQuery = () => ({
  query: gql`
    query ContactPage {
      pageBy(uri: "/contact-form") {
        id
        content(format: RENDERED)
      }
    }
  `,
});
