export const ACTION = {
  UPDATE_LIST: 'UPDATE_LIST',
};

export const INITIAL_STATE = ({ pageInfo, postList }) => ({
  cursor: pageInfo?.endCursor,
  hasMore: pageInfo?.hasNextPage,
  postList,
});

export const postsReducer = (state, { payload, type }) => {
  if (type === ACTION.UPDATE_LIST) {
    return {
      ...state,
      cursor: payload?.pageInfo?.endCursor,
      hasMore: payload?.pageInfo?.hasPreviousPage,
      postList: [...state.postList, ...payload?.nodes],
    };
  }
};

export const scrollToShowMore = () =>
  window.scrollBy({
    top: window.innerHeight / 2,
    left: 0,
    behavior: 'smooth',
  });
